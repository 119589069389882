import { Controller } from '@hotwired/stimulus'
import { View, Director } from '@millicast/sdk'

export default class extends Controller {
  static targets = [
    'remoteView'
  ]

  static values = {
    streamName: String,
    streamAccountId: String
  }

  connect () {
    this.millicastView = this.createStreamingInstance()
    this.connectToStream()
  }

  disconnect () {
    this.millicastView.stop()
  }

  async connectToStream () {
    try {
      await this.millicastView.connect({
        events: [
          'active',
          'inactive',
          'stopped',
          'viewercount'
        ]
      })
    } catch (e) {
      console.log('Connection failed, handle error', e)
    }
  }

  createStreamingInstance () {
    const tokenGenerator = () => Director.getSubscriber({
      streamName: this.streamNameValue,
      streamAccountId: this.streamAccountIdValue
    })

    const millicastView = new View('yourStreamName', tokenGenerator, this.remoteViewTarget)

    const activeSources = new Set()

    millicastView.on('broadcastEvent', (event) => {
      const { name, data } = event
      switch (name) {
        // There is a new active source
        case 'active':
          activeSources.add(data.sourceId)
          console.log('Active Stream.')
          break
        // A source became inactive
        case 'inactive':
          activeSources.delete(data.sourceId)
          if (activeSources.size === 0) {
            console.log('No active Stream.')
          }
          break
        case 'viewercount':
          console.log('Viewer count:', data.viewercount)
          break
        default:
          break
      }
    })

    return millicastView
  }
}
